import React from "react"
import { Flex, NavLink, Styled, Text } from "theme-ui"
import { Link } from "gatsby"

import GoogleButton from "@buttons/GoogleButton"
import Layout from "@layouts/form"
import LoginForm from "@forms/LoginForm"

export default () => (
  <Layout title="Zaloguj">
    <Styled.h4 style={{ color: `#000`, fontSize: `26px`, textAlign: `center` }}>
      Zaloguj się
    </Styled.h4>
    <Styled.p
      style={{
        letterSpacing: `0px`,
        color: `#7D7D7D`,
        fontSize: `13px`,
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: `25px`,    
        textAlign: `center`,
        paddingTop: '2rem',
        paddingBottom: '2rem'
      }}
    >
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s
    </Styled.p>
    <LoginForm />
    <GoogleButton />
    <Flex>
      <Text sx={{ color: `#000`, fontFamily: `body`}}>Nie masz konta?</Text>
      <NavLink
      as={Link}
      to="/register"
      sx={{
        color: `#000`,
        textAlign: "right",
        fontWeight: 600,
        fontFamily: "Poppins",
        fontSize: `16px`,
      }}
    >
      Zarejestruj się
    </NavLink>
    </Flex>
  </Layout>
)
