import React from "react"
import { NavLink } from "theme-ui"
import { Link } from "gatsby"

export default () => (
  <NavLink
    as={Link}
    to="/request-password-recovery"
    sx={{
      alignSelf: 'center',
      fontSize: `15px`,
      fontFamily: `body`,
      fontWeight: 400,
      letterSpacing: `0px`,
      color: `muted`,
      m: '1rem 0'
    }}
  >
    Zapomniałeś hasła?
  </NavLink>
)
