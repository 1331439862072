import React from "react"
import { useIdentityContext } from "react-netlify-identity"
import { Button, Text } from "theme-ui"
import GoogleLogo from "@assets/btn-google.svg"

export default ({ children }) => {
  const { loginProvider } = useIdentityContext()
  const onClick = () => loginProvider("google")

  return (
    <Button onClick={onClick} variant="google">
      <GoogleLogo />
      <Text>Zaloguj z Google</Text>
      {children}
    </Button>
  )
}
