import React from "react"
import { Checkbox, Label } from "theme-ui"
import { useField } from "formik"

export default ({children, label, sx, ...props}) => {
  const [field, meta, helpers] = useField(props)

  return (
    <Label sx={sx}>
      <Checkbox {...field} {...meta} {...helpers} {...props} />{label}
    </Label>
  )
}
