import React from "react"
import { Formik } from "formik"
import { useIdentityContext } from "react-netlify-identity"
import { navigate } from "gatsby"
import { object, string } from "yup"
import { errorMessages, errorMessagesPL } from "@const"
import LoginForm from "./LoginForm"

export default () => {
  const { loginUser } = useIdentityContext()
  const formikProps = {
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    onSubmit: ({ email, password, remember }, {setFieldError, setSubmitting}) =>
      loginUser(email, password, remember)
        .then(() => {
          setSubmitting(false)
          navigate("/")
        })
        .catch(error => {
          const isInvalidPassword = String(error).includes(errorMessages.invalidPassword);
          const isNoUserFound = String(error).includes(errorMessages.noUserFound)
          if(isInvalidPassword) {
            setFieldError('password', errorMessagesPL.invalidPassword);
          }
          if(isNoUserFound) {
            setFieldError('email', errorMessagesPL.noUserFound);
          }
          setSubmitting(false)
        }),
    validationSchema: object().shape({
      email: string().email(errorMessages.emailNotValid).required(errorMessages.required),
      password: string().min(8, errorMessages.passwordTooShort).required(errorMessages.required),
    }),
  }
  return (
    <Formik {...formikProps} children={props => <LoginForm {...props} />} />
  )
}
