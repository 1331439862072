import React from "react"
import { Form } from "formik"
import { Button, Flex } from "theme-ui"

import RequestPasswordRecovery from "@buttons/RequestPasswordRecovery"
import CheckboxInput from "@fields/CheckboxInput"
import EmailInput from "@fields/EmailInput"
import PasswordInput from "@fields/PasswordInput"

export default ({ dirty, isSubmitting, isValid, values, ...rest }) => (
  <Flex as={Form} sx={{ flexWrap: `wrap` }} {...rest}>
    <EmailInput name="email" placeholder="Email" />
    <PasswordInput name="password" placeholder="Hasło" />
    <CheckboxInput
      name="remember"
      label="Pozostaw zalogowanym"
      sx={{ flex: `1 0 50%`, mt: `1rem` }}
    />
    <RequestPasswordRecovery />
    <Button
      disabled={!dirty || !isValid || isSubmitting}
      type="submit"
      variant="submit"
    >
      Zaloguj się
    </Button>
  </Flex>
)
